@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
}

:after, :before {
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: Poppins, sans-serif;
  line-height: 1.5;
}

button, input, textarea, select {
  font: inherit;
}

h1 {
  font-size: 60px;
}

@media (width <= 320px) {
  h1 {
    font-size: 40px;
  }
}

a {
  color: inherit;
  z-index: 2;
  pointer-events: auto;
  text-decoration: none;
}

ul {
  list-style: none;
}

.section {
  min-height: 100vh;
  padding: 5rem 1rem 1rem;
  overflow: hidden;
}

@media (width >= 650px) {
  .section {
    padding: 5rem;
  }
}
/*# sourceMappingURL=index.628c0b6f.css.map */
